<template>
  <div>
    <v-container fluid class="py-8">
      <Notify ref="myNotify" :details="message"></Notify>
      <v-row>
        <v-col cols="12">
          <h5 class="text-h3 text-typo font-weight-bold ms-4 mb-3">
            {{ $t("sidebar.inquiries Log") }}
          </h5>
        </v-col>
        <!-- inquiries list -->
        <v-col cols="12">
          <v-col cols="12">
            <v-card color="light">
              <v-app-bar color="light" class="border-left-primary">
                <v-toolbar-title>
                  {{ $t("sidebar.Inquiry List") }}
                </v-toolbar-title>
                <button
                  class="py-5 mb-0 ms-auto"
                  v-if="
                    $store.getters['auth/str_per'].indexOf('inquiry-create') >
                    -1
                  "
                  @click="add_inquiry()"
                >
                  <v-icon class="custome-btn btn-primary">fas fa-plus</v-icon>
                </button>
              </v-app-bar>
              <FormFilter
                :filters="filters"
                @get_data="get_inquiries"
                @reset="resetFilters"
              ></FormFilter>
              <Table
                @event_btn="event_btn"
                :loading="loading"
                @action="action"
                :btns="data"
                :headers="header"
                :items="inquiries"
                :paginate="paginate"
                :btn_table="btn_table"
                :btn_loading="btn_loading"
                @change_pre_page="ChangePrePage"
                @get_data_paginate="handlePageChange"
                @set_option="set_option"
                ref="table"
              ></Table>
            </v-card>
          </v-col>
        </v-col>
      </v-row>
      <Modal :data="modal_data"></Modal>
    </v-container>
  </div>
</template>
<script>
import Table from "../Components/PaginationTable.vue";
import Notify from "../Components/New/Notify.vue";
import Modal from "../Components/New/Modal.vue";
import FormFilter from "../Components/Filter.vue";
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("inquiry");
export default {
  name: "Inquiry-Page",
  components: {
    Table,
    Notify,
    FormFilter,
    Modal,
  },
  data() {
    return {
      options: {},
      filters: [],
      paginate: {
        page: 1,
        itemsPerPage: 0,
        total: 0,
      },
      btn_table: {
        excel: true,
        print: true,
      },
      btn_loading: {
        excel: false,
        print: false,
      },
      modal_data: {
        size: "500px",
        title: "",
      },
      loading: true,
      message: {
        msg: null,
        type: null,
      },
      data: [
        {
          type: "icon",
          text: "edit_list",
          color: "bg-gradient-success",
          icon: "mdi-pencil",
          permission: "inquiry-update",
        },
        {
          type: "icon",
          text: "destroy_list",
          color: "bg-gradient-danger",
          icon: "mdi-delete",
          permission: "inquiry-delete",
        },
      ],

      header: [
        {
          text: this.$i18n.t("inquiry.Inquery Text en"),
          value: "inquery",
          align: "center",
        },
        {
          text: this.$i18n.t("inquiry.Inquery Text ar"),
          value: "inquery_ar",
          align: "center",
        },
        // {text: this.$i18n.t('inquiry.Input Name'),value: 'inputs.name',align: 'center'},
        {
          text: this.$i18n.t("inquiry.Input Type"),
          value: "inputs.name",
          align: "center",
        },
        {
          text: this.$i18n.t("general.Action"),
          value: "btns",
          align: "center",
        },
      ],
      list_of_inputs: [],
      form_data: {
        input_id: "",
        inquery: "",
        inquery_ar: "",
      },
      form_style: [
        {
          col: "12",
          col_id: "col_attribute",
          type: "select",
          type_select: "single",
          label: this.$i18n.t("inquiry.Input Type"),
          value_text: "input_id",
          items: [],
          error: null,
          rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
        },
        {
          col: "12",
          type: "textarea",
          label: this.$i18n.t("inquiry.Inquery Text en"),
          error: null,
          value_text: "inquery",
          rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
        },
        {
          col: "12",
          type: "textarea",
          label: this.$i18n.t("inquiry.Inquery Text ar"),
          error: null,
          value_text: "inquery_ar",
          rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
        },
      ],
    };
  },
  computed: {
    ...mapState(["inquiries"]),
  },
  methods: {
    ...mapActions(["getInquiries", "destroyInquiry"]),
    set_option(val) {
      this.options = val;
    },
    get_inquiries(reset = false, first = false, type = null) {
      this.loading = true;
      const formData = new FormData();
      if (!reset) {
        this.filters.filter(function (filter) {
          formData.append(filter.name, filter.value);
        });
      }
      if (type == "pre_page") {
        formData.append("paginate", this.paginate.itemsPerPage);
      }
      if (first) {
        formData.append("first", first);
      }
      this.getInquiries({ page: this.paginate.page, data: formData }).then(
        (response) => {
          // console.log(response);
          if (first) {
            this.list_of_inputs = response.data.inputs;
          }
          this.paginate.total = response.data.inqueries.total;
          this.paginate.itemsPerPage = parseInt(
            response.data.inqueries.per_page
          );
          this.loading = false;

          if (reset) {
            this.filters = response.data.filters;
            this.options = {};
            this.reset_option = true;
            this.$refs.table.resetOption();
          }
        }
      );
    },
    action(id, name, item) {
      if (name == "edit_list") {
        this.edit_list(item);
      } else if (name == "destroy_list") {
        this.destroy_list(id);
      }
    },
    add_inquiry() {
      this.$store.commit("form/SET_DIALOG", true);
      this.modal_data.title = this.$i18n.t("inquiry.New Inquiry");
      this.$store.dispatch("form/setFormData", this.form_data);
      this.$store.dispatch("form/setData", this.form_style);
      this.$store.state.form.style_form[0].items = this.list_of_inputs;
      this.$store.commit("form/INFO_CALL", {
        name: "inquiry/addInquiry",
      });
    },
    edit_list(item) {
      this.$store.commit("form/SET_DIALOG", true);
      this.modal_data.title =
        this.$i18n.t("inquiry.Edit Inquiry") + " # " + item.inquery_lang;
      this.$store.dispatch("form/setData", this.form_style);
      this.$store.state.form.style_form[0].items = this.list_of_inputs;
      this.form_data.inquery = item.inquery;
      this.form_data.inquery_ar = item.inquery_ar;
      this.form_data.input_id = parseInt(item.input_id);
      this.$store.dispatch("form/setFormData", this.form_data);
      this.$store.commit("form/INFO_CALL", {
        name: "inquiry/editInquiry",
        id: item.id,
      });
    },
    destroy_list(id) {
      this.$swal({
        title: this.$i18n.t("general.Are you sure?!"),
        text: this.$i18n.t("You won't be able to revert this!"),
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: this.$i18n.t("general.Yes, delete it!"),
        cancelButtonText: this.$i18n.t("general.No, cancel!"),
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.destroyInquiry(id).then(
            (response) => {
              // console.log(response)
              this.$swal.fire(
                this.$i18n.t("general.Deleted!"),
                response.data.message,
                "success"
              );
            },
            (error) => {
              console.log(error);
              this.$swal.fire(
                this.$i18n.t("general.Error"),
                this.$i18n.t("general.There error please try again"),
                "error"
              );
            }
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === this.$swal.DismissReason.cancel
        ) {
          this.$swal.fire(
            this.$i18n.t("general.Cancelled"),
            this.$i18n.t("general.Cancelled Delete"),
            "error"
          );
        }
      });
    },
    handlePageChange(page) {
      console.log(page);
      this.paginate.page = page;
      this.get_inquiries();
    },
    resetFilters() {
      this.paginate.page = 1;
      this.get_inquiries(true);
    },
    ChangePrePage(page) {
      this.paginate.itemsPerPage = parseInt(page);
      this.paginate.page = 1;
      this.get_inquiries(false, false, "pre_page");
    },
    event_btn(name) {
      if (name == "excel") {
        this.export_excel();
      } else if (name == "print") {
        this.print_table();
      }
    },
    print_table() {
      document.querySelector("table").classList.add("print");
      window.print();
    },
    export_excel() {
      this.btn_loading.excel = true;
      const formData = new FormData();
      this.filters.filter(function (filter) {
        formData.append(filter.name, filter.value);
      });
      this.$http({
        url: this.$baseURL + "api/inquires/export",
        responseType: "blob",
        method: "post",
        data: formData,
      }).then(
        (response) => {
          this.btn_loading.excel = false;
          // console.log(response);
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel",
            })
          );

          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "inqueries_" + new Date().toLocaleString() + ".xlsx"
          );
          document.body.appendChild(link);
          link.click();
        },
        (error) => {
          this.btn_loading.excel = false;
          if (error.response.status != 401) {
            this.message = {
              msg: this.$i18n.t("general.there is problem"),
              type: "Danger",
            };
          }
        }
      );
    },
  },
  mounted() {
    this.get_inquiries(true, true);
    document.title = this.$i18n.t("sidebar.Inquiry List");
  },
};
</script>
